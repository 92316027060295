// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { auth } from '@/firebaseConfig';

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

let app;

const vuetify = createVuetify({
  components,
  directives
});

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    app.use(router);
    app.use(vuetify);
    app.mount('#app');
  }
});
