<template>
  <div class="logo-text">
    <span class="logo-bold">YOUNGUH</span> CLUB
  </div>
</template>

<style scoped>
.logo-text {
  font-family: Arial, Helvetica, sans-serif; /* Fallback fonts similar to Urbanist */
  color: white;
  display: inline-block;
}

.logo-bold {
  font-weight: bold;
}
</style>
