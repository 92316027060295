<template>
  <header class="header" :class="{'widescreen-padding': display.lgAndUp}">
    <div class="first-line">
      <div class="logo-section" @click="logoClick">
        <img
          class="logo-img"
          src="@/assets/symbol-white-logo.png"
          alt="Symbol Logo"
        />
        <TextLogo style="font-size: 20px;"></TextLogo>
      </div>
    </div>
  </header>
  <v-divider style="width: 100%;"></v-divider>
</template>

<script>
import TextLogo from "@/components/TextLogo.vue";

import { useDisplay } from 'vuetify';

export default {
  components: {
    TextLogo
  },
  data() {
    return {
      errorMessage: "",
      display: {}
    };
  },
  async mounted() {
    this.display = useDisplay();
  },
  methods: {
    logoClick() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.header {
  height: 6vh;
  width: 100% - 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: white;
  padding-top: 13px;
  padding-right: 20px;
  padding-left: 20px;
}

.widescreen-padding {
  padding-right: 50px;
  padding-left: 50px;
}

.first-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.logo-section {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo-img {
  height: 28px;
  padding-left: 5px;
  padding-right: 8px;
}

nav a {
  display: block;
  color: white;
  text-decoration: none;
  padding: 10px 0;
  transition: color 0.3s;
}

nav a:hover {
  color: #ccc;
}
</style>
